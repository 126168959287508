@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Eliza';
  src:
    local('MyFont'),
    url('../fonts/eliza/Eliza-Medium.woff') format('woff');
}

::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
body::-webkit-scrollbar {
  display: none;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  display: none;
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  display: none;
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.fill-warning-primary {
  fill: #cea100;
}
